// Lightweight wrapper for maps
import $ from 'jquery';

const DATA_KEY = 'rebs.map';
const LOCATIONIQ_KEY = kairos.config.LOCATIONIQ_KEY;
const MAPBOX_OPTIONS = {
    attributionControl: false, //need this to show a compact attribution icon (i) instead of the whole text
    style: 'https://tiles.locationiq.com/v3/streets/vector.json?key=' + LOCATIONIQ_KEY,
    antialias: true,
}

export default class Map {
  constructor( element, lat, lng, zoom, fuzzy ) {
    this.element = element;
    this.lat = lat;
    this.lng = lng;
    this.zoom = zoom;
    this.fuzzy = fuzzy === "True";

    this.loadMapBoxGl().then((mapboxgl) => {
      this.mapboxgl = mapboxgl;
      this.map = this.initialize();
      this.map.on('load', (event) => {
        this.update();
      });
    });

    $(this.element).data(DATA_KEY, this);
  }

  loadMapBoxGl() {
      // Webpack public path (i.e. where all assets all placed) is unknown to webpack until runtime,
      // so we need to inject it here in order to properly fetch dynamic modules.
    __webpack_public_path__ = kairos.config.STATIC_URL;

    // Dynamically import Mapbox JS and CSS
    return Promise.all([
        import('mapbox-gl'),
        import('mapbox-gl/dist/mapbox-gl.css')
    ])
    .then(([{ default: mapboxgl}, {}]) => {
      return mapboxgl;
    });
  }

  initialize() {
    let options = Object.assign({}, MAPBOX_OPTIONS, {
      container: this.element,
      center: this.getMapBoxCenter(),
      zoom: parseInt(this.zoom),
    });

    let map = new this.mapboxgl.Map(options);

    // Add map controls
    let nav = new this.mapboxgl.NavigationControl({ showCompass: false });
    map.addControl(nav, 'bottom-right');
    map.addControl(new this.mapboxgl.FullscreenControl());
    map.scrollZoom.disable();

    let plugin = this;

    if (this.fuzzy) {
      // Add a new layer, with a circle around the map coordinates
      map.on('load', function() {
        let radius = kairos.agency.promote_location_range / 1000, // km
            geoCircle = plugin.getGeoJSONCircle(plugin.getMapBoxCenter(), radius);

        map.addSource("polygon", geoCircle);
        map.addLayer({
          "id": "polygon",
          "type": "fill",
          "source": "polygon",
          "layout": {},
          "paint": {
            "fill-color": kairos.agency.map_fuzzy_color,
            "fill-opacity": 0.3,
            "fill-outline-color": kairos.agency.map_fuzzy_color,
          }
        });
      });
    } else {
      // Add a marker
      let marker = new this.mapboxgl.Marker({
        color: '#f94600',
      });

      marker.setLngLat(this.getMapBoxCenter())
            .addTo(map);
    }

    this.addEventHandlers();

    return map;
  }

  getMapBoxCenter() {
    return [ this.lng, this.lat ];
  }

  getGeoJSONCircle( center, radiusInKm, points ) {
    if (!points) points = 128;
    var coords = {
        latitude: center[1],
        longitude: center[0]
    };
    var km = radiusInKm;
    var ret = [];
    var distanceX = km/(111.320*Math.cos(coords.latitude*Math.PI/180));
    var distanceY = km/110.574;
    var theta, x, y;
    for(var i=0; i<points; i++) {
        theta = (i/points)*(2*Math.PI);
        x = distanceX*Math.cos(theta);
        y = distanceY*Math.sin(theta);

        ret.push([coords.longitude+x, coords.latitude+y]);
    }
    ret.push(ret[0]);
    return {
        "type": "geojson",
        "data": {
            "type": "FeatureCollection",
            "features": [{
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [ret]
                }
            }]
        }
    };
  }

  update() {
    if (this.map) {
      this.map.resize();
      this.map.triggerRepaint();
    }
  }

  addEventHandlers() {
    var plugin = this;

    // Watch for a touch event and disable map drag
    // Rationale - https://codeburst.io/the-only-way-to-detect-touch-with-javascript-7791a3346685
    $(document).one('touchstart', function (e) {
      if (plugin.map) {
        plugin.map.touchZoomRotate.enable();
        plugin.map.dragPan.disable();
      }
    });
  }
}
