// Custom navbar behaviour
import $ from 'jquery';

export default class Navbar {
  constructor( element ) {
    this.element = element;
    this.initialize();
  }

  initialize() {
    let $navbar = $(this.element);

    // If the header is transparent, and is sticky positioned, remove transparency while page is scrolled
    if ($navbar.hasClass('navbar-transparent') && $navbar.css("position") === "fixed") {
      document.addEventListener('scroll', (event) => {
        const scrollTop = $(event.target).scrollTop();
        if ( scrollTop > $navbar.height() ) {
          // Remove transparency when scrolling down
          $navbar.removeClass("navbar-transparent");
        } else if ( scrollTop == 0 ) {
          // Add it back when scrolled to top
          $navbar.addClass("navbar-transparent");
        }
      }, true);
    }
  }
}
