import Cookies from 'js-cookie';

const DISABLED_CHECKBOX_SELECTOR = '.form-check-disabled';
const DISABLED_CHECKBOX_ALERT = 'Necesare - nu poate fi deselectat. Ele sunt necesare pentru funcții de bază precum navigarea și securizarea site-ului. Acest website nu poate funcționa corect fără aceste cookies.';
const OPEN_DETAILS_SELECTOR = '.open-details';
const CLOSE_DETAILS_SELECTOR = '.close-cookie svg';
const SAVE_SELECTION_SELECTOR = '.save-selection';
const ACCEPT_ALL_SELECTOR = '.accept-all';
const ACCEPT_ALL_DELAY = .5 * 1000; // .5 seconds
const COOKIES_OPTIONS_KEY_ROOT = 'cookies';

export default class CookiesOptions {
  constructor( element, shouldHide ) {
    this.element = element;
    this.shouldHide = shouldHide || false;
    this.initialize();
  }

  initialize() {
    this.loadCookieOptions();
    this.addEventHandlers();
  }

  /**
   * Returns the name of the cookie for the checkbox with `category`.
   * This will be the where the value of the checkbox will be stored and fetched from.
   */
  getCookieName(category) {
    return `${COOKIES_OPTIONS_KEY_ROOT}-${category}`
  }

  /**
   * Returns all checkboxes that should be saved & restored to and from cookies
   */
  getCheckboxes() {
    return $(this.element).find("[type='checkbox']").not(':disabled');
  }

  /**
   * Save chckboxes state to their respective cookie
   */
  setCookieOptions() {
   this.getCheckboxes().each((i, check) => {
      let cookie = this.getCookieName($(check).attr('category'));
      Cookies.set(cookie, $(check).prop('checked'), { expires: 10 * 365 }); // 10 years expiry
    });
  }

  /**
   * Signal that an update is due in our non-wekpack marketing tags code
   */
  triggerConsentUpdate() {
    // Call a global function
    setOrUpdateConsent('update');
  }

  /**
   * Restores the state of the cookie options checkbox from its cookie
   */
  loadCookieOptions() {
    var hasSetCookie = false;
    this.getCheckboxes().each((i, check) => {
      let cookie = this.getCookieName($(check).attr('category')),
          value = Cookies.get(cookie);
      if (value) {
        $(check).prop('checked', value === 'true');
        hasSetCookie = true;
      }
    });
    if (hasSetCookie) {
      this.hideSelf();
    }
  }

  hideSelf() {
    if (this.shouldHide) {
      $(this.element).hide();
    }
  }

  addEventHandlers() {
    let $el = $(this.element);
    $el.on('click', DISABLED_CHECKBOX_SELECTOR, (event) => {
      alert(DISABLED_CHECKBOX_ALERT)
    });
    $el.on('click', OPEN_DETAILS_SELECTOR, (event) => {
      $el.addClass("opened");
    });
    $el.on('click', CLOSE_DETAILS_SELECTOR, (event) => {
      $el.removeClass("opened");
    });
    $el.on('click', SAVE_SELECTION_SELECTOR, (event) => {
      this.setCookieOptions();
      this.triggerConsentUpdate();
      this.hideSelf();
    });
    $el.on('click', ACCEPT_ALL_SELECTOR, (event) => {
      this.getCheckboxes().prop('checked', true);
      this.setCookieOptions();
      this.triggerConsentUpdate();
      setTimeout(() => {
        this.hideSelf();
      }, ACCEPT_ALL_DELAY);
    });
  }
}
