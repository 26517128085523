import { testMode } from './test'

export function isConfiguratorMode() {
  /**
   * Returns true if we're inside an iframe (configurator mode (edit and preview) and template selection mode).
   * Returns false otherwise or if in test mode.
   */
  if (testMode()) {
    return false;
  }

  // see https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
  // for more details
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}
