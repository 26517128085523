
export default class WhatsappChathead {
  constructor( element ) {
    this.element = element;

    this.initialize();
  }

  initialize() {
    // NOTE: We're sniffing the user agent here, to add a different format for chathead links on mobile devices.
    // This is not fool-proof, but in most cases, being on a mobile device is equivalent to being able to use the
    // WhatsApp app; this also has the advantage of gracefully defaulting to the WhatsApp /send/ API.
    let mobileHref = $(this.element).attr('href-mobile');
    if ( mobileHref && /Mobi/i.test(navigator.userAgent) ) {
      $(this.element).attr('href', mobileHref);
    }
  }
}
