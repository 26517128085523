const EVENT_DATA_ATTRIBUTE = 'event';
const TRIGGER_EVENT_TYPES = 'click submit';

export default class EventDispatcher {
  constructor( element ) {
    this.element = element;
    this.initialize();
  }

  initialize() {
    // Whenever any `TRIGGER_EVENT_TYPES` occurs on an element with an `EVENT_DATA_ATTRIBUTE`,
    // fire a custom event on the dispatcher element (usually `document`)
    $(this.element).on(TRIGGER_EVENT_TYPES, `[data-${EVENT_DATA_ATTRIBUTE}]`, (originalEvent) => {
      let $target = $(event.target),
          eventName = $target.data(EVENT_DATA_ATTRIBUTE);

      // Only trigger if the original target has an EVENT_DATA_ATTRIBUTE
      if (eventName) {
        this.dispatchEvent(eventName, $target, originalEvent);
      }
    });
  }

  /**
   * Creates and fires a custom event
   * @param  {string} name          The name of the event to fire
   * @param  {Object} target        The target of the original event
   *                                i.e. a DOM element with an EVENT_DATA_ATTRIBUTE
   * @param  {Object} originalEvent The original event that triggred the dispatch,
   *                                i.e. one of the events in TRIGGER_EVENT_TYPES
   */
  dispatchEvent(name, target, originalEvent) {
    const event = new CustomEvent(name, {
      detail: { target, originalEvent },
    });
    this.element.dispatchEvent(event);
  }
}
