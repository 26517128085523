/**
 * This function imports all SVG images from the `../svg` folder, so that webpack can process them.
 *
 * The imported files are not used in any of the scripts, but require to be imported by the build pipeline.
 */
export function importSVG(modules) {

  function importAll(r) {
    return r.keys().map(r);
  }

  let v = importAll(modules);
}

export function initializePopovers($) {
  $('[data-toggle="popover"]').popover({ html: true });

  // Remove all popovers when clicking something other than an active popover
  $('html').on('click', function(e) {
    let target = e.target;
    // When clicking a child of a popover, target the popover
    if ($(target).parent().data('toggle') == "popover") {
      target = target.parentElement;
    }
    if (typeof $(target).data('original-title') == 'undefined' && !$(target).parents().is('.popover.in')) {
      $('[data-original-title]').popover('hide');
    }
  });
}
